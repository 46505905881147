import {Link} from "react-router-dom";

export default function BackButton() {
    return (
        <Link className="button-back" to='/'>
            <div>
                На главную
            </div>
        </Link>
    )
}