import {useState} from "react";

export default function AddOptionList(props) {


    const [dataList, setDataList] = useState(props.options);
    const [valueSlider, setValueSlider] = useState(null);
    const [keyList, setKeyList] = useState('');
    const [textValue, setTextValue] = useState('');

    const changeSliderHandler = (e) => {

        if(keyList) {
            setDataList({...dataList, [keyList]:e.target.value});
        }

        setValueSlider(e.target.valueAsNumber);
    };

    const endInputSlider = () => {
        createUpdate();
    };

    const changeSelectHandler = (e) => {
       const index =  e.target.selectedIndex;

        setKeyList( e.target.options[index].getAttribute('name'));
        setValueSlider(Number(e.target.value));
        createUpdate();
    };


    const changeAddHandler = (e) => {
        setDataList({...dataList, [textValue]:Number(e.target.value)});
       createUpdate();
    };

    const changeInputAddHandler = (e) => {
        setTextValue( e.target.value);
      // createUpdate();
    };

    const createUpdate = () => {
        props.onUpdate(props.category, props.name, dataList);
    };

    return (
        <>
            <div className="slider-container" >
                <span>{props.minVal || 0}</span>
                <input type="range" onMouseUp={endInputSlider} onChange={changeSliderHandler} min={props.minVal || 0} max={props.maxVal || 100} value={valueSlider || dataList[Object.keys(dataList)[0] ]} className="slider"/>
                <span>{props.maxVal || 100}</span>
            </div>

            <div className="select-list">
                <select onChange={changeSelectHandler}>
                    {
                        Object.keys(dataList).map((el, index) => {
                        return (<option value={dataList[el]} name={el} key={index}>{el} </option>)
                    })
                    }
                </select>
            </div>

            <div className="form-add_option">
                <input onChange={changeInputAddHandler} type="text"/>
                <button onClick={changeAddHandler}>Добавить</button>
            </div>
        </>
    )

}
