import TileWrapper from '../Tiles/TileWrapper';
import Tile from '../Tiles/Tile';
import Toggle from "../Tiles/Toggle";
import {useEffect, useState} from "react";
import Cookies from "universal-cookie";
import AddOptionToggleList from "../Tiles/AddOptionToggleList";

export default function Audio(props) {
    const [settings, setSettings] = useState(null);
    const cookies = new Cookies();
    const authKey = cookies.get('auth');

    console.log("SETTINGS", settings);

    //fetch(`http://localhost/Sites/smarthouse/core/index.php/user/updateSettings?auth=${props.authKey || authKey}&settings=${preparedData}&menu=tv`, {

    const sendUpdate = (data) => {
        const preparedData = JSON.stringify(data);
        fetch(`/core/index.php/user/updateSettings?auth=${props.authKey || authKey}&settings=${preparedData}&menu=tv`, {
            method: 'POST', body: {
                auth: 'VrNvOzONk1IBnUSQkzYjr6CO8Z',
                settings: preparedData
            }
        }).then(res => res.json()).then(res => console.log(res));
    };

    //fetch(`http://localhost/Sites/smarthouse/core/index.php/user/getSettings?auth=${authKey}&menu=tv`).then(
    useEffect(() => {
        fetch(`/core/index.php/user/getSettings?auth=${authKey}&menu=tv`).then(
            res => res.json()
        ).then((res) => {
            if (res.success) {
                let schema = JSON.parse(res['data'][0]['value']);
                setSettings(schema);
            } else {
                cookies.remove('auth');
            }
        });
    }, []);


    const updateHandler = (category, key, value) => {
        let send = {...settings, [category]: {...settings[category], [key]: value}};
        setSettings(send);
        sendUpdate(send);
    };

    if (settings) {
        return (
            <div>
                <TileWrapper>

                 {/*   <Tile title={"Прием вещательного телевидения"}>
                        <Toggle posName='generalEnabled' onUpdate={updateHandler} category="tvAnalog" name="enabled"
                                value={settings['tvAnalog']['enabled']}/>
                        <AddOptionList minVal={0} maxVal={100} onUpdate={updateHandler} category="tvAnalog"
                                       name="systems"
                                       options={settings['tvAnalog']['systems'] || {}}/>
                    </Tile>*/}


                    <Tile title={"Прием вещательного телевидения"}>
                        <AddOptionToggleList minVal={0} maxVal={100} onUpdate={updateHandler} category="tvAnalog"
                                       name="systems"
                                       options={settings['tvAnalog']['systems'] || {}}/>
                    </Tile>
                    <Tile title={"Прием вещательного телевидения"}>
                        <AddOptionToggleList minVal={0} maxVal={100} onUpdate={updateHandler} category="tvAnalog"
                                       name="systems"
                                       options={settings['tvAnalog']['systems'] || {}}/>
                    </Tile>
                    <Tile title={"Прием вещательного телевидения"}>
                        <AddOptionToggleList minVal={0} maxVal={100} onUpdate={updateHandler} category="tvAnalog"
                                       name="systems"
                                       options={settings['tvAnalog']['systems'] || {}}/>
                    </Tile>
                    <Tile title={"Прием вещательного телевидения"}>
                        <AddOptionToggleList minVal={0} maxVal={100} onUpdate={updateHandler} category="tvAnalog"
                                       name="systems"
                                       options={settings['tvAnalog']['systems'] || {}}/>
                    </Tile>


                </TileWrapper>
            </div>
        );
    } else {
        return <div>Awaiting</div>
    }

}