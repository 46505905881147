function SendValueOnChange(e) {

}

export default function Slider(props) {


    console.log('props', props.value);

    const onChange = (e) => {
        props.onUpdate(props.category, props.name,e.target.value);
    };

    return (
        <div className="slider-container">
            <span>{props.minVal || 0}</span>
            <input type="range" onMouseUp={(e) => { onChange(e)} } min="1" max="100" defaultValue={props.value || 50} className="slider"/>
            <span>{props.maxVal || 100}</span>
        </div>
    )

}