import {BrowserRouter, Route, Routes} from "react-router-dom";
import './styles/main.css';

import Menu from "./Components/Menu/Menu";
import Welcome from "./Components/Welcome/Welcome";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import Registration from "./Components/Welcome/Registration";
import Light from "./Components/Pages/Light";
import LogIn from "./Components/Welcome/LogIn";
import NavMenu from "./Components/Menu/NavMenu";
import MainLayout from "./Layouts/MainLayout";
import Logout from "./Components/Logout";
import Activation from "./Components/Welcome/Activation";
import Admin from "./Components/Admin/Admin";
import TV from  "./Components/Pages/TV";

function App() {
    return (
        <div className="app">
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<LogIn/>}/>
                    <Route path="/signup" element={<Registration/>}/>
                    <Route path='/activation' element={<Activation/>} />
                    <Route path="/admin" element={<Admin/>}/>

                    <Route element={<ProtectedRoutes/>}>
                        <Route path={"/menu"} element={<MainLayout/>}>
                            <Route path="/menu" element={<Menu/>}/>
                            <Route path="/menu/light" element={<Light/>}/>
                            <Route path="/menu/audio" element={<Light/>}/>
                            <Route path="/menu/tv" element={<TV/>}/>
                            <Route path="/menu/cinema" element={<Light/>}/>
                        </Route>

                        <Route path="/logout" element={<Logout/>}/>
                    </Route>

                    <Route path="/" element={<Welcome/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
