import TileWrapper from '../Tiles/TileWrapper';
import PoliceButton from "../PoliceButton";
import VoiceButton from "../VoiceButton";
import CategoryTile from "../Tiles/CategoryTile";

export  default function Menu() {

    return (
        <div>
            <TileWrapper>
               <CategoryTile className='centred' path="/menu/light" category="menu" name="light" title={"Система электропитания и освещения"}/>
               <CategoryTile className='centred' path="/menu/audio" category="menu" name="tv" title={"Система аудио- и видеотехники Мультирум"}/>
                <CategoryTile path="/menu/tv" className='centred title-hidden' title={"Система приема эфирного и спутникового телевидения"}/>
                <CategoryTile path="/menu/tv" className='centred title-hidden' title={"Система домашнего кинотеатра"}/>
                <CategoryTile path="/menu/tv" className='centred title-hidden' title={"Система охранно-пожарной сигнализации"}/>
                <CategoryTile path="/menu/tv" className='centred title-hidden' title={"Система видеонаблюдения"}/>
                <CategoryTile path="/menu/tv" className='centred title-hidden' title={"Система вентиляции и кондиционирования воздуха"}/>
                <CategoryTile path="/menu/tv" className='centred title-hidden' title={"Система отопления"}/>
                <CategoryTile path="/menu/tv" className='centred title-hidden' title={"Система метеоконтроля"}/>
                <CategoryTile path="/menu/tv" className='centred title-hidden' title={"Система холодного и горячего водоснабжения"}/>
                <CategoryTile path="/menu/tv" className='centred title-hidden' title={"Система обслуживания территории"}/>
            </TileWrapper>
            <PoliceButton/>
            <VoiceButton/>
        </div>
    )
}