import {useState} from "react";

export default function Toggle(props) {
    const [check, setCheck] = useState(!props.value);

    const changeHandler = (e) => {
        setCheck(!check);
        props.onUpdate(props.category, props.name, check);
    };

    return (
        <div className="toggle-container">
            <p>Вкл</p>
        <label className="switch">
            <input type="checkbox" checked={check} onChange={changeHandler}/>
            <span className="toggle round"/>
        </label>
            <p>Выкл</p>
        </div>
    )

}