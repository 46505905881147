

export default function PoliceButton() {

    return (
            <div className="button-voice">
                Voice
            </div>

    )
}