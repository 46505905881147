import {Link, redirect} from "react-router-dom";
import {useEffect} from "react";
import {useState} from "react";
import Cookies from 'universal-cookie';
import * as PropTypes from "prop-types";
import {useNavigate} from "react-router";
import BackButton from "./BackButton";

export default function LogIn() {

   // const requstURL = http://localhost:80/Sites/smarthouse/core/index.php/user/login?login=${login}&password=${password}
  //  const requestURL = ''
    const navigate = useNavigate();
    const [error, setError] = useState('');

    function handleSubmit(e) {
        e.preventDefault();

        const login = e.target.login.value;
        const password = e.target.password.value;

        if(login.length<4 || password.length<4) {
            setError('Проверьте введенные данные');
            return;
        }

        fetch(`/core/index.php/user/login?login=${login}&password=${password}`, {
            METHOD: "GET"
        }).then(res => res.json())
            .then(res => {
                console.log('result', res);
                if (res.success) {
                    const cookies = new Cookies();
                    cookies.set('auth', res.auth);
                   // cookies.set('name', res?.name);
                    navigate('/menu');

                } else {
                    setError(res.reason || 'непредвиденная ошибка');
                }
            });
    }

    return (
        <div className="wrapper-centred bg centred">
            <div className="wrapper-center">
                <div className="modal-login " id="modal_login">
                    <h4>Вход</h4>
                    <form onSubmit={handleSubmit}>
                        <h3 id="message-error">{error}</h3>
                        <input type="text" placeholder='Логин' autoSave='true' name="login" id="input_login"/>
                        <input type="password" placeholder='Пароль' autoSave='true' name="password" id="input_password"/>
                        <input type="submit" value="Войти"/>
                    </form>
                  <Link to='/signup'>Зарегистрироваться</Link>
                </div>
            </div>
            <BackButton/>
        </div>
    )

}