
import * as React from "react";
import {Outlet, useLocation} from 'react-router';
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";

const useAuth = () => {
   const cookies = new Cookies();

   const auth = cookies.get('auth');

   if(auth=='partial'){
       return auth;
   }

   return !!auth;
};

export default function ProtectedRoutes() {
    const isAuth = useAuth();
    const location = useLocation();

    if(isAuth==='partial'){
        return <Navigate to='/activation' replace state={{from:location}}/>
    }

    if(isAuth){
        return  <Outlet/>
    }



    return <Navigate to='/' replace state={{from:location}}/>

}


