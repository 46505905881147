export default function Tile(props){

    let cssList = 'tile';

    if(props.className){
        cssList+=' '+props.className;
    }

    if(props.disabled) {
        cssList += ' tile-disabled';
    }

    return (
        <div className={cssList}>
            <h3>{props.title || "empty"}</h3>
            {props.children}
        </div>
    )

}