import {Outlet} from 'react-router';
import NavMenu from "../Components/Menu/NavMenu";

export default function MainLayout() {

    return (
        <>
            <NavMenu/>
            <Outlet/>
        </>
    );
}



