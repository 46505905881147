import TileWrapper from '../Tiles/TileWrapper';
import Tile from '../Tiles/Tile';
import Toggle from "../Tiles/Toggle";
import Slider from "../Tiles/Slider";
import {useEffect, useState} from "react";
import Cookies from "universal-cookie";
import AddOptionList from "../Tiles/AddOptionList";



export default function Light(props) {

    const [settings, setSettings] = useState(null);

    const cookies = new Cookies();
    const authKey = cookies.get('auth');

    const sendUpdate = (data) => {
        const preparedData = JSON.stringify(data);

        fetch(`/core/index.php/user/updateSettings?auth=${props.authKey || authKey}&settings=${preparedData}`, {
            method: 'POST'
        }).then(res => res.json()).then(res => console.log(res));
    };


    const handleChangeGeneralEnabled = (e) => {

    };

    //fetch(`http://localhost/Sites/smarthouse/core/index.php/user/getSettings?auth=${authKey}&menu=light`).then(
    useEffect(() => {
        fetch(`/core/index.php/user/getSettings?auth=${authKey}&menu=light`).then(
            res => res.json()
        ).then((res) => {
            if (res.success) {
                let schema = JSON.parse(res['data'][0]['value']);
                console.log('schema', schema);
                setSettings(schema);
            } else {
                cookies.remove('auth');
            }
        });

    }, []);



    const updateHandler = (category, key, value) => {
        let send = {...settings, [category]:{...settings[category],[key]:value}};
            setSettings(send);
            sendUpdate(send);
    };

    if (settings) {
        return (
            <div>
                <TileWrapper>
                    <Tile short="general" data={settings['general']}
                          title={"Управление энергосбережением (экономия до 40%)"}>

                        <Toggle   category="general" name="enabled" onUpdate={updateHandler}
                                value={settings['general']['enabled']}/>
                        <Slider category="general" name="value" onUpdate={updateHandler} value={settings['general']['value']}/>
                    </Tile>

                    <Tile title={"Управление уровнями освещения во всех комнатах"}>
                        <Toggle posName='generalEnabled' onUpdate={updateHandler} category="allRooms" name="enabled"
                                value={settings['allRooms']['enabled']}/>
                        <AddOptionList minVal={0} maxVal={100} onUpdate={updateHandler} category="allRooms" name="rooms"
                                       options={settings['allRooms']['rooms'] || {}}/>
                    </Tile>

                    <Tile short="general" data={settings['general']}
                          title={"Управление уровнями освещения во всех комнатах"}>
                        <Toggle/>
                    </Tile>
                    <Tile short="general" data={settings['general']}
                          title={"Управление проходными зонами (лестничные проходы)"}>
                        <Toggle   category="forestWay" name="enabled" onUpdate={updateHandler}
                                  value={settings['forestWay']['enabled']}/>
                        <Slider category="forestWay" name="value" onUpdate={updateHandler} value={settings['forestWay']['value']}/>
                    </Tile>
                    <Tile short="general" data={settings['general']}
                          title={"Следящий свет"}>
                        <Toggle   category="followLight" name="enabled" onUpdate={updateHandler}
                                  value={settings['followLight']['enabled']}/>
                        <Slider category="followLight" name="value" onUpdate={updateHandler} value={settings['followLight']['value']}/>
                    </Tile>
                    <Tile short="general" data={settings['general']}
                          title={"Управление шторами и жалюзи с электроприводом"}>
                        <Toggle   category="curtains" name="enabled" onUpdate={updateHandler}
                                  value={settings['curtains']['enabled']}/>
                        <Slider category="curtains" name="value" onUpdate={updateHandler} value={settings['curtains']['value']}/>
                    </Tile>
                    <Tile short="general" data={settings['general']}
                          title={"Управление вентиляторами"}>
                        <Toggle   category="fans" name="enabled" onUpdate={updateHandler}
                                  value={settings['fans']['enabled']}/>
                        <Slider category="fans" name="value" onUpdate={updateHandler} value={settings['fans']['value']}/>
                    </Tile>

                </TileWrapper>
            </div>
        );
    } else {
        return <div>Awaiting</div>
    }

}