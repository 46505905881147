import Toggle from "./Toggle";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";

export default function CategoryTile(props){

    let cssList = 'tile';

    const [className,setClassName] = useState(cssList);

    if(!localStorage.getItem('menu-category')){
        localStorage.setItem('menu-category',JSON.stringify({}));
    }

    let menuCategory =  localStorage.getItem('menu-category');
    menuCategory = JSON.parse(menuCategory);

    const onUpdate = (category, name, value) => {

       let menuCategory =  localStorage.getItem('menu-category') || {};
       menuCategory = JSON.parse(menuCategory);

       localStorage['menu-category'] = JSON.stringify({...menuCategory,[name]:value});
        console.log('value', menuCategory);
        if(!value) {
            cssList+=' tile-disabled';
            setClassName(cssList);
        }else{
            cssList = 'tile';
            setClassName(cssList);
        }
    };

    useEffect(()=> {
        let menuCategory =  localStorage.getItem('menu-category') || {};
        menuCategory = JSON.parse(menuCategory);
        if(!menuCategory[props.name]){

            cssList+=' tile-disabled';
            setClassName(cssList);
        }
    },[]);

    return (
        <div className={className}>
            <Toggle category='main' name={props.name} value={menuCategory[props.name]} onUpdate={onUpdate}/>

            <Link to={props.path}> <h3>{props.title || "empty"}</h3>
            {props.children}
            </Link>
        </div>
    )

}