import React from "react";
import {Link} from "react-router-dom";
import '../../styles/welcome.css';

export default function Welcome() {

    return (
        <React.Fragment>
            <div className="wrapper bg">
                <div className="menu">
                    <div className="login">
                        <span className="button button-registration"><Link to="/signup">Регистрация</Link></span>
                        <Link to="/login"><span className="button button-gradient" id="button_login">Вход</span></Link>
                    </div>
                </div>
                <div className="logo">
                    <h1 className="color-text">ZODIAQ</h1>
                </div>
                <div className="contacts">
                    <p>Улица Александра Пироговского 56/22</p>
                    <p>+380673452209</p>
                    <span><a href="#" target="_blank"><img src="../../images/instagram.png" alt=""/></a></span>
                </div>
                <div className="button-support">Чат</div>
            </div>

        </React.Fragment>
    )
}