

export default function PoliceButton() {

    return (
            <div className="button-police">
                !
            </div>

    )
}