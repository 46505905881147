import Cookies from "universal-cookie";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";

export default function Admin(){

    const superSecretKey = '328338283213a';
    const navigate = useNavigate();
    const [data, setData] = useState({});

    useEffect(() => {

    //    fetch(`http://localhost:80/Sites/smarthouse/core/index.php/user/getUsers?superkey=${superSecretKey}`, {
        fetch(`/core/index.php/user/getUsers?superkey=${superSecretKey}`, {

            method:"GET"

        }).then(res => res.json())
            .then(res => {
                console.log('result', res);
                if(res.success) {
                    setData(res.data);
                }
            });

    }, []);

   const clickHandler = (e) => {
      new Cookies().set('auth',e.target.value);
       navigate('/menu');
   };

if(data) {
    return (
        <div className={"admin-menu"}>
            <div className='table-menu'>
            <span> Id </span>
            <span> Login </span>
            <span> Имя </span>
            <span> Код активации </span>
            <span> Активирован </span>
            </div>
            {
                Object.keys(data).map((el,index) => {
                    return (<div key={data[index]['id']}>
                       <span> {data[index]['id']}</span>
                       <span> {data[index]['login']}</span>
                       <span> {data[index]['name']}</span>
                       <span> {data[index]['registration_key']}</span>
                       <span> {data[index]['activated'] === 1 ? 'Да':'Нет'}</span>
                       <button value={data[index]['authkey']} onClick={clickHandler}>Изменить</button>
                    </div>)
                })
            }
        </div>
    )
}else{
    return (<h1>No data!</h1>);
}
}