import {Navigate} from "react-router";
import Cookies from "universal-cookie";

export default function Logout() {

    new Cookies().remove('auth');

    return (
        <Navigate to={'/'}/>
    )

}