import {Link, redirect} from "react-router-dom";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Cookies from "universal-cookie";
import BackButton from "./BackButton";

export default function Registration() {

    const [error, setError] = useState('');
    const navigate = useNavigate();

    function handleSubmit(e){
        e.preventDefault();

        const login = e.target.login.value;
        const password = e.target.password.value;
        const passwordRepeated = e.target.passwordRepeated.value;
        const name = e.target.name.value;

        if(name.length<2){
            setError("Введите имя");
            return;
        }

        if(password!==passwordRepeated){
            setError("Пароли не совпадают");
            return;
        }
       // fetch(`http://localhost:80/Sites/smarthouse/core/index.php/user/createUser?login=${login}&password=${password}&name=${name}`, {
        fetch(`/core/index.php/user/createUser?login=${login}&password=${password}&name=${name}`, {
            method:"POST"
        }).then(res => res.json())
            .then(res => {

                if(res.success) {
                    const cookies = new Cookies();
                    cookies.set('auth', 'partial');
                    navigate('/menu');
                }

                setError(res.reason || 'Ошибка');
            });
    }


    return (
        <div className="wrapper-centred bg centred">
            <div className="modal-login " id="modal_login">

                <h4>Регистрация</h4>
                <form onSubmit={handleSubmit}>
                    <h3 id="message-error">{error}</h3>
                    <input type="text" placeholder="Логин" name="login" id="input_login"/>
                    <input type="text" placeholder="Имя" name="name" id="input_name"/>
                    <input type="password" name="password"  placeholder="Пароль" id="input_password"/>
                    <input type="password" name="passwordRepeated" placeholder="Повторите пароль" id="input_password"/>
                    <input type="submit" value="Зарегистрироваться"/>
                    <Link to='/login'>Уже есть аккаунт?</Link>
                </form>

            </div>

            <BackButton/>
        </div>
    )

}