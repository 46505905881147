import {useState} from "react";

export default function AddOptionList(props) {


    const [dataList, setDataList] = useState(props.options);
    const [keySelect, setKeySelect] = useState(Object.keys(props.options)[0]);
    const [textValue, setTextValue] = useState("");

    const [checkboxStatus, setCheckboxStatus] = useState(dataList[Object.keys(props.options)[0]] );


    const changeToggleHandler = (e) => {
        setCheckboxStatus(!checkboxStatus);
        setDataList({...dataList, [keySelect]:checkboxStatus });
        createUpdate();
    };

    const changeSelectHandler = (e) => {
        const index = e.target.selectedIndex;
        setKeySelect(e.target.options[index].getAttribute('name'));
        setCheckboxStatus(dataList[keySelect]);
        createUpdate();
    };

    const changeAddHandler = (e) => {
        console.log(keySelect,"KS");
        const obj = Object.assign({[textValue]:!checkboxStatus}, dataList);
        console.log('obj', obj);
        setDataList(obj);
       createUpdate();
    };

    const changeInputAddHandler = (e) => {
        setTextValue(e.target.value);
    };

    const createUpdate = () => {
           console.log('sent',props.name, dataList);
        props.onUpdate(props.category, props.name, dataList);
    };

    return (
        <>
            <div className="toggle-container">
                <p>Вкл</p>
                <label className="switch">
                    <input type="checkbox" checked={!!checkboxStatus} onChange={changeToggleHandler}/>
                    <span className="toggle round"/>
                </label>
                <p>Выкл</p>
            </div>

            <div className="select-list">
                <select onChange={changeSelectHandler}>
                    {
                        Object.keys(dataList).map((el, index) => {
                            return (<option value={dataList[el]} name={el} key={index}>{el} </option>)
                        })
                    }
                </select>
            </div>

            <div className="form-add_option">
                <input onChange={changeInputAddHandler} type="text"/>
                <button onClick={changeAddHandler}>Добавить</button>
            </div>
        </>
    )

}
