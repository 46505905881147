import {Link, redirect} from "react-router-dom";
import {useEffect} from "react";
import {useState} from "react";
import Cookies from 'universal-cookie';
import * as PropTypes from "prop-types";
import {useNavigate} from "react-router";
import BackButton from "./BackButton";

export default function LogIn() {

    const navigate = useNavigate();
    const [error, setError] = useState('');

    function handleSubmit(e) {
        e.preventDefault();

        const code = e.target.code.value;

      //  fetch(`http://localhost:80/Sites/smarthouse/core/index.php/user/activateUser?code=${code}`, {
        fetch(`/core/index.php/user/activateUser?code=${code}`, {
            method: "POST"
        }).then(res => res.json())
            .then(res => {
                console.log('result', res);

                if (res.success) {
                    const cookies = new Cookies();
                    cookies.set('auth', res.auth);

                    navigate('/menu');

                } else {
                    setError(res.reason || 'Неверный код');
                }

            });
    }

    return (
        <div className="wrapper-centred bg centred">
            <div className="wrapper-center">
                <div className="modal-login " id="modal_login">
                    <h4>Введите код для активации</h4>
                    <form onSubmit={handleSubmit}>
                        <h3 id="message-error">{error}</h3>
                        <input type="text" name="code" placeholder='Код активации' id="input_login"/>
                        <input type="submit" value="Использовать"/>
                    </form>
                </div>
            </div>
            <BackButton/>
        </div>
    )

}